/*

--------------------------------------------------------------------------------
Common iframe functions like resizing etc.
--------------------------------------------------------------------------------

*/

(function($) {

    "use strict";

    if( typeof ori !== "object" || typeof ori.messenger !== "object" ) return;

    var self = ori.messenger,
        VISIBLE = ":visible",
        DEFAULT_SCROLL_DURATION = 700,
        KENDO_WINDOW_PADDING_H = 70,
        KENDO_WINDOW_PADDING_V = 60;

    ori.messenger = $.extend(true, ori.messenger, {

        // default additional options
        options : {
            defaultIframeId : "contentIframe",
            defaultIframeTheme : "",
            defaultOverlayElement : "body",
            paramFrameId : "frameid",
            paramFrameTheme : "theme",
            noresize : ".colorbox-noresize", // no resize class in iframe element, block resizing; used on multiple places in Sitecore
            // resize event callback
            // ( by default not set; when set executes imediately after resize )
            onResizeFinished : undefined,
            // default iframe load timeout
            timeout : 20000,
            animation : false, // enable size changing animation
            animationSpeed : 500, // animation speed
            iframeHorizontalScrollBarOffset : 0,
            iframeHorizontalScrollBar: '.nicescroll-rails.nicescroll-rails-hr',
            iframeParentContainer: '.w-iframe' // iframe parent and virtual scrollbar container class name
        },

        // map of additional message actions
        actionsMap: {
            "resize": "resizeIframe",
            "scroll": "scrollTop",
            "scrollToOffset": "scrollTopToOffset",
            "scrollToIframeElement" : "scrollToIframeElement",
            "showTransparentLayer": "showTransparentLayer",
            "hideTransparentLayer": "hideTransparentLayer",
            "parentLogin": "openLoginDialogue",
            "forgotPassword": "openForgotPasswordDialogue",
            "forgotPasswordToggle": "toggleForgotPasswordDialogue",
            "history": "history",
            "iframeFloatHorizontalScrollBar": "iframeFloatHorizontalScrollBar",
            "designLibraryNotes": "designLibraryNotesSwitcher",
            "designLibraryBackgroundColor": "designLibraryBackgroundColor",
            "setLocation": "setLocation",
            "resizeWindowPopup": "resizeWindowPopup"
        },

        setLocation: function(msg) {
            var href = self.getMessageParam(msg, "href");
            ori.redirect.setWindowLocation(href);
        },

        /*
		 * Design library notes switcher (show/hide markup, CSS, notes)
         * TODO : would be nice to move this into separate submodule for DL, as it isn't a common iframe function.
		 */
        designLibraryNotesSwitcher: function(msg) {
            var dl = ori.designLibraryIframe;
            if (!dl) {
                self.error("Iframe design library functionality not found. Can't switch code comments.");
                return;
            }
            dl.switchNotes(self.getMessageParam(msg, "note"));
        },

        /*
		 * Design library backgroundColorSwitcher
         * TODO : would be nice to move this into separate submodule for DL, as it isn't a common iframe function.
		 */
        designLibraryBackgroundColor: function(msg) {
            var color = "#" + self.getMessageParam(msg, "color");
            $("body").css("background-color", color);
            $(".w-content").css("background-color", color);
        },

        /*
		 * Toggle Forgot Password and Login in Burger menu
         */
        toggleForgotPasswordDialogue: function(msg) {
            var toggle, loginIframeId, forgotIframeId, forgotPasswordClose;
            toggle = self.getMessageParam(msg, "toggle");
            forgotPasswordClose = '.forgot-password-close';
            loginIframeId = '#login-iframe';
            forgotIframeId = '#initForgotPasswordIfrm';


            if (toggle == 'forgot') {
                $(loginIframeId).fadeOut(300, function() {

                    var srcTmp = $(forgotIframeId).data('iframesrc');
                    $(forgotIframeId).attr("src", srcTmp).removeClass('invisible');

                    $(forgotIframeId).on("load", function() {
                        $(this).fadeIn(300);
                    });
                    $(forgotPasswordClose).removeClass('invisible').fadeIn(300);
                });

            } else if (toggle == 'login') {
                $('.forgot-password-close').fadeOut(300);
                $(forgotIframeId).fadeOut(300, function() {
                    $(loginIframeId).fadeIn(300);
                });
            }

        },

        loadIframe: function(iframeId, settings) {
            var $iframe = $("#" + iframeId);
            $iframe.prop('src', $iframe.data("iframe-src"));
            if (settings) {
                self.addIframeSettings(iframeId, settings);
            }
        },

        // appending / removing iframe settings ----------------------------------------
        /**
         * Adds one iframe settings into internal iframes configuration.
         * @param iframeId ID of iframe
         * @settings Settings object for a given iframe.
         */
        addIframeSettings: function(iframeId, settings) {
            if (!iframeId || !settings) {
                self.error("Iframe ID or settings not specified, can't add to configuration.");
                return;
            }
            // append the iframe settings into iframes configuration object
            if (!self.iframes) self.iframes = {};
            self.iframes[iframeId] = settings;
            self.info("Settings for iframe with ID '%s' were added successfully.", iframeId);
            // apply overlay when is true
            if (!settings.overlay) return;
            self.showTransparentLayer('?frameid=' + iframeId);
            $("#" + iframeId).on("load", function() {
                settings.timeout = setTimeout(function() {
                    self.errorIframeLoadCallback($("#" + iframeId), settings);
                }, self.options.timeout);
            });
        },

        errorIframeLoadCallback: function($iframe, settings) {
            $iframe = $($iframe);
            var spin = ori.spinner,
                iframeId = $iframe.attr("id"),
                overlayLoadError = settings.overlayLoadError;
            self.warning("Overlay for iframe with ID '%s' is going to be removed by load event timeout.", iframeId);
            // remove spinner
            spin.removeOverlayFrom($iframe);
            // remove content of iframe and replace it by error page
            $iframe.replaceWith($("<div></div>").attr("id", iframeId).html(overlayLoadError));
        },

        /**
         * Removes specified iframe settings from internal configuration.
         */
        removeIframeSettings: function(iframeId) {
            if (!iframeId) {
                self.error("Iframe ID not specified, can't remove settings.");
                return;
            }
            if (!self.iframes || !self.iframes[iframeId]) {
                self.error("Iframe settings for ID '%s' not found, can't remove it.", iframeId);
                return;
            }
            delete self.iframes[iframeId];
        },

        /**
         * Enables connecting multiple event handlers for iframe resizing.
         * onResizeFinished callback possibility is still preserved, shouldn't
         * be used though.
         */
        dispatchResizedEvent: function(w, h, frameId, success) {
            var callback = self.options.onResizeFinished,
                event = $.Event("iframeResized");
            if (typeof callback == "function") {
                self.info("Executing onResizeFinished callback ...");
                callback(w, h, frameId, success);
            }
            event.width = w;
            event.height = h;
            event.frameId = frameId;
            event.success = success;
            self.info(
                "Triggering iframe resize event : #%s - %s x %s, success : %s ...",
                frameId, w, h, success
            );
            self.trigger(event);
        },

        /**
         * Tries to return proper iframe ID. Message should include frameid,
         * otherwise this method falls back to returning the default iframe ID
         * from options.
         */
        getIframeId : function( msg ) {
            return self.getMessageParam( msg, self.options.paramFrameId ) || self.options.defaultIframeId;
        },

        /**
         * Reload by My pages Top menu selected menu item
		history=back  // 1 step back
		history=forward  // 1 step forward
		history=-n  // n steps back
		history=n  // n steps forward
		history=home  // go to consultant home page
		history=menu  // go to consultant section by active/selected menu item in tool bar
         */
        history: function(msg) {
            var history = self.getMessageParam(msg, "history"),
                goLink;
            if (!history) {
                self.warning("No history parameter found. Action cancelled.");
                return;
            }
            switch (history) {
            case "menu":
                // get link to active menu item in consultant top bar
                goLink = $('.w-consultants-top-menu .top-menu-common-link-item .active').data('history');
                if (goLink)
                    ori.redirect.setWindowLocation(goLink);
                else
                    self.warning("Active menu item link not found. Please check Consultant top bar links.");
                break;
            case "home":
                // get link to main menu item in consultant top bar
                goLink = $('.w-consultants-top-menu .top-menu-common-link-item:first a').data('history');
                if (goLink)
                    ori.redirect.setWindowLocation(goLink);
                else
                    self.warning("Home menu item link not found. Please check Consultant top bar links.");
                break;
            case "back":
                // go back 1 step in history
                window.history.go(-1);
                break;
            case "forward":
                // go forward 1 step in history
                window.history.go(1);
                break;
            default:
                // go N steps in history
                if ($.isNumeric(history)) window.history.go(history);
            }
        },

        /**
         * Resizes given iframe to a specified width and / or height.
         */
        resizeIframe: function(msg) {
            var w = parseInt(self.getMessageParam(msg, 'width')),
                h = parseInt(self.getMessageParam(msg, 'height')),
                docw = parseInt(self.getMessageParam(msg, 'docwidth')),
                doch = parseInt(self.getMessageParam(msg, 'docheight')),
                sc = 'no',
                frameId = self.getIframeId(msg);
            if (isNaN(docw) || docw == undefined) {
                docw = w;
                self.info('docwidth param empty, reset to %s from width parameter.', w);
            }
            if (isNaN(doch) || doch == undefined) {
                doch = h;
                self.info('docheight param empty, reset to %s from height parameter.', h);
            }
            var $iframe = $('#' + frameId);

            self.setIframeSize(w, h, frameId, sc, docw, doch);
            if ($iframe.parent().is(self.options.iframeParentContainer))
            {
                if (ori.virtualScrollBar && typeof ori.virtualScrollBar.updateEshopHorizontalScrollBar == "function") ori.virtualScrollBar.updateEshopHorizontalScrollBar();
            }
            
        },

        resizeWindowPopup: function (msg) {
            self.info("resizing window popup");
            // return if options.noresize is used in iframe class, this option is rendered from sitecore IframeClass item
            if ($('#' + self.getIframeId(msg) + self.options.noresize).length > 0) return;

            self.resizeIframe(msg);
            $('#' + self.getMessageParam(msg, 'popupId')).data('kendoWindow').setOptions({
                width: parseInt(self.getMessageParam(msg, 'width')) + KENDO_WINDOW_PADDING_H,
                height: parseInt(self.getMessageParam(msg, 'height')) + KENDO_WINDOW_PADDING_V
            });
        },

        /**
         * Resizes specified iframe. Iframe is defined by it's ID.
         * Configuration of resizing behavior of a given iframe can be specified
         * in iframes configuration property of messenger module. See iframes
         * property description.
         * @param w Requested iframe width. Will not change when width is fixed.
         * @param h Requested iframe height. Will not change when height is fixed.
         * @param frameId Specific iframe id ( string without leading "#" character ).
         * Default value of this.defaultIframeId is set when no ID was specified.
         */
        setIframeSize: function (w, h, frameId, sc, docw, doch) {
            // if no frame id specified, set default one
            frameId = frameId || self.options.defaultIframeId;
            var $iframe = $("#" + frameId);
            var tolerance = 1; 
            $iframe.attr("scrolling", sc);
            if (!$iframe.length) {
                self.warning("Iframe #%s not found. Nothing to resize.", frameId);
                return;
            }
            // if "all" is specified as width, set it as "100%"
            if (w == "all") w = "100%";
            var fixed = "fixed",
                heightClass = "",
                widthClass = "",
                widthClasses = [],
                heightClasses = [],
                processed = false,
                horizontalScrollBarHeight,
                iframeMarginHeight,
                iframeSettings = self.iframes[frameId] || {},
                step,
                i;
            // set width to document width
            // For some reason there is 1 pixel difference sometimes ("tolerance" variable). In this case do not add scrollbar
            if (docw && docw > w + tolerance) {
                w = docw;
                self.info("Iframe #%s set to document view mode, fixed width : %s.", frameId, iframeSettings.fixedWidth);
                if ($iframe.parent().is(self.options.iframeParentContainer))
                {
                    ori.virtualScrollBar.addEshopHorizontalScrollBar();
                }
            }
            if ($(self.options.iframeParentContainer).length > 0) {
                horizontalScrollBarHeight = $(self.options.iframeHorizontalScrollBar).innerHeight();
                iframeMarginHeight = (typeof ($iframe.css('margin-top')) != "undefined") ? $iframe.css('margin-top').replace(/[^-\d.]/g, '') : "0";
                $iframe.closest($(self.options.iframeParentContainer)).css({ height: h + Math.round(iframeMarginHeight) + horizontalScrollBarHeight + self.options.iframeHorizontalScrollBarOffset });
            }
            // set fixed width when specified in iframe config
            if (iframeSettings.fixedWidth)
                w = fixed;
            // reset width to a step when steps are defined in config
            // TODO : full width / rest available height sizing ?
            else if (iframeSettings.widthSteps)
                for (i = 0; i < iframeSettings.widthSteps.length; i++) {
                    step = iframeSettings.widthSteps[i];
                    // create a list of all possible width classes
                    if (step.cssClass) widthClasses.push(step.cssClass);
                    // percentage works only for full width
                    if (
                        !processed &&
                        (!isNaN(w) || w == "100%") &&
                        (step.width >= w || step.width == "100%")
                    ) {
                        w = step.width;
                        widthClass = step.cssClass;
                        processed = true;
                    }
                }
            // check zero width
            if (!w) {
                w = fixed;
                self.warning("Zero width given, no width resize will happen.");
            }
            // do the same for height
            // no "all" height checking so far
            if (iframeSettings.fixedHeight)
                h = fixed;
            // reset width to a step when steps defined in config
            else if (iframeSettings.heightSteps && !isNaN(h)) {
                processed = false;
                for (i = 0; i < iframeSettings.heightSteps.length; i++) {
                    step = iframeSettings.heightSteps[i];
                    if (step.cssClass) heightClasses.push(step.cssClass);
                    if (!processed && !isNaN(h) && step.height >= h) {
                        h = step.height;
                        heightClass = step.cssClass;
                        processed = true;
                    }
                }
            }
            // check zero height
            if (!h) {
                h = fixed;
                self.warning("Zero height given, no height resize will happen.");
            }
            // check for both dimensions fixed
            if (w == fixed && h == fixed) {
                self.warning(
                    "Both width and height are fixed, no resize for iframe #%s.",
                    frameId
                );
                self.dispatchResizedEvent(w, h, frameId, false);
                return;
            }
            // TODO : this was needed before for older browsers, maybe not needed anymore
            // toolbar( false );
            self.info("Resizing iframe #%s to %s x %s ...", frameId, w, h);
            // resize width
            if (w != fixed) {
                // remove all already added width classes & inline width style
                $iframe
                    .css({ width: "" })
                    .removeClass(widthClasses.join(" "));
                // do the actual iframe width resize here
                // done either via CSS class or with inline style
                // we check here for some rules existing in stylesheet with given class
                // TODO : check in all supported browsers
                if (widthClass) {
                    self.info("Adding CSS class %s to iframe #%s ...", widthClass, frameId);
                    $iframe.addClass(widthClass);
                } else {
                    if (w.toString().indexOf("%") < 0) w = w + "px";
                    self.info("Adding inline width %s to iframe #%s ...", w, frameId);
                    if (self.options.animation) {
                        $iframe.animate(
                            { width: w },
                            { duration: self.options.animationSpeed }
                        );
                    } else {
                        $iframe.css({ width: w });
                    }
                }
            }
            // the same for height
            if (h != fixed) {
                $iframe
                    .css({ height: "" })
                    .removeClass(heightClasses.join(" "));
                // actual iframe height resize here
                if (heightClass) {
                    self.info("Adding CSS class %s to iframe #%s ...", heightClass, frameId);
                    $iframe.addClass(heightClass);
                } else {
                    if (h.toString().indexOf("%") < 0) h = h + "px";
                    self.info("Adding inline height %s to iframe #%s ...", h, frameId);
                    if (self.options.animation) {
                        $iframe.animate(
                            { height: h },
                            { duration: self.options.animationSpeed }
                        );
                    } else {
                        $iframe.css({ height: h });
                    }

                }
            }

            if ($iframe.parent().is(self.options.iframeParentContainer)) {
                var parentWidth = $iframe.parent().width();
                $iframe.css({ width: parentWidth });
                if(ori.virtualScrollBar && typeof ori.virtualScrollBar.updateEshopHorizontalScrollBar == "function") ori.virtualScrollBar.updateEshopHorizontalScrollBar();
            }
            // trigger resize event callbacks
            self.dispatchResizedEvent(w, h, frameId, true);
        },

        /**
         * Scrolls window to top on demand from iframe.
         */
        scrollTop: function() {
            self.info("Scrolling window to top ...");
            window.scrollTo(0, 0);
        },

        /**
         * Scrolls window to certain position on demand from iframe
         */
        scrollTopToOffset: function(msg) {
            var offset = parseInt(self.getMessageParam(msg, "offset")),
                frameId = self.getIframeId( msg ),
                $iframe = $("#" + frameId);

            if (isNaN(offset)) {
                self.warning(
                    "Can't scroll to vertical position, given offset is not a number : %s.",
                    offset
                );
                return;
            }
            if (!$iframe.length) {
                self.warning("Iframe #%s not found. Can't scroll it.", frameId);
                return;
            }

            var offsetTop = parseInt($iframe.offset().top);

            self.info("Scrolling iframe #%s window to vertical position %s ...", frameId, offset);
            window.scrollTo(0, offsetTop + offset);
        },

        /**
         * Scrolls the page so that the element in iframe becomes visible.
         * Scrolling is done via animation if the duration parameter is above zero.
         * Simplified version here - scrolling always on document, more complex case
         * is in ori.kendo.validator.js, focusFirstInvalidInput() method.
         */
        scrollToIframeElement : function( msg ) {
            var frameId = self.getIframeId( msg ),
                $iframe = $( "#" + frameId );
            if( ! $iframe.length ) return;
            if( ! $iframe.is( VISIBLE ) ) {
                self.warning( "Found iframe is not visible, can't scroll to it." );
                return;
            }
            // TODO : kendo might be missing - duplicate the code in ori ? schisis !
            var elementRect = kendo.boundingRect( $iframe );
            if( ! elementRect ) {
                self.warning( "Couldn't get iframe bounding box, can't scroll to it." );
                return;
            }
            self.info( "Iframe bounding rectangle : %o", elementRect );
            if( kendo.isElementInViewport( $iframe ) ) {
                self.info( "Found iframe is already fully visible in page, no scrolling needed." );
                return;
            }
            var duration = parseInt( self.getMessageParam( msg, "duration" ), 10 ),
                scrollProps = kendo.scrollProps( document ),
                newScroll = null,
                checkScroll = function( axis ) {
                    var suffix1 = ( axis === "x" ) ? "Width" : "Height",
                        suffix2 = ( axis === "x" ) ? "Left" : "Top",
                        max =  window[ "inner" + suffix1 ] || document.documentElement[ "client" + suffix1 ];
                    scrollProps[ axis ].elementPosition = parseInt( self.getMessageParam( msg, axis ), 10 ) || 0;
                    elementRect[ axis ].min += scrollProps[ axis ].elementPosition;
                    elementRect[ axis ].max = elementRect[ axis ].min +
                        parseInt( self.getMessageParam( msg, "s" + axis ), 10 ) || 0;
                    if( elementRect[ axis ].min >= 0 && elementRect[ axis ].max <= max ) return;
                    if( ! scrollProps[ axis ].canScroll )
                        self.warning( "Can't scroll in axis %s.", axis );
                    else {
                        scrollProps[ axis ].delta = parseInt( self.getMessageParam( msg, "d" + axis ), 10 ) || 0;
                        if( ! newScroll ) newScroll = {};
                        newScroll[ "scroll" + suffix2 ] =
                            scrollProps[ axis ].scroll + elementRect[ axis ].min - scrollProps[ axis ].delta;
                        self.info( "Scroll settings for axis %s : %o", axis, scrollProps[ axis ] );
                    }
                },
                axes = [ "x", "y" ];
            if( isNaN( duration ) ) duration = DEFAULT_SCROLL_DURATION;
            axes.forEach( checkScroll );
            if( ! newScroll ) return;
            self.info( "Trying to scroll element into viewport, new scroll settings : %o ...", newScroll );
            $( "html,body" ).stop().animate( newScroll, duration );
        },

        /**
         * Displays transparent layer on iframe. This means the iframe is lifted up
         * in z-aixs via z-index, and below it over the whole body the transparent
         * layer gets displayed.
         */
        showTransparentLayer: function(msg) {
            var frameId = self.getIframeId( msg ),
                $iframe = $("#" + frameId),
                frameTheme = self.getMessageParam(msg, self.options.paramFrameTheme) || self.options.defaultIframeTheme,
                spinnerOptions = {};
            // set spinner options, if theme=light spinner is not display in overlay
            // TODO : probably wrong logic
            if( frameTheme === "light" ) spinnerOptions.display = "none";
            spinnerOptions.position = "fixed";
            if (!$iframe.length) {
                self.warning("Iframe #%s not found. No transparent layer applied.", frameId);
                return;
            }
            // apply to body element - full width overlay
            ori.spinner.applyOverlayTo(self.options.defaultOverlayElement,
                {
                    overlay: { zIndex: 1 }, // overlay mask z-index in body
                    spinner: spinnerOptions, // implemented: position: fixed, display: none
                    iframe: $iframe // iframe id
                }
            );
            ori.messenger.info("Full width transparent layer applied.");
        },

        /**
         * Removes transparent layer from iframe
         */
        hideTransparentLayer: function(msg) {
            var frameId = self.getIframeId( msg ),
                $iframe = $("#" + frameId);
            if (!$iframe.length) {
                self.warning("Iframe #%s not found. No transparent layer removed.", frameId);
                return;
            }
            ori.spinner.removeOverlayFrom(self.options.defaultOverlayElement);
            $iframe.css('z-index', '0'); // TODO : zero needed ? isn't clearing it good enough ?
            ori.messenger.info("Transparent layer removed.");
        },

        /**
         * Opens login dialogue in popup or burger menu.
         */
        openLoginDialogue: function() {
            var loginModule;
            try {
                loginModule = ori.popup.popupLogin || ori.burgerMenu;
            } catch (error) {
            }
            if (!loginModule) {
                self.error("Can't show forgot password dialogue, login module or burger menu not found.");
                return;
            }
            loginModule.openLoginDialogue();
        },

        /**
         * Opens forgot password dialogue in popup or burger menu.
         */
        openForgotPasswordDialogue: function() {
            var loginModule;
            try {
                loginModule = ori.popup.popupLogin || ori.burgerMenu;
            } catch (error) {
            }
            if (!loginModule) {
                self.error("Can't show forgot password dialogue, login module or burger menu not found.");
                return;
            }
            loginModule.openForgotPasswordDialogue();
        }

    });

    ori.messenger.on("iframeResized", function(event) {
        var frameId = event.frameId,
            settings = self.iframes[frameId];
        ori.messenger.info("iframeResized() %o", event);
        // unbind load event
        //$("#" + frameId).off("load"); // quick fix of frozen login issue
        // clear the load timeout
        if (!settings)
            self.warning(
                "Iframe settings for ID '%s' not found, can't remove iframe load timeout.",
                frameId
            );
        else {
            $("#" + frameId).off("load"); // quick fix of frozen login issue
            clearTimeout(settings.timeout);
        }
        // remove overlay
        ori.spinner.removeOverlayFrom("#" + frameId);
    });

    ori.messenger.info("Common iframe methods successfully appended.");

    // TODO : describe this functionality more, what is it good for ?
    $(window).on("orientationchange" + self.ns, function() {
        if (!$("#" + self.options.defaultIframeId).length) return;
        ori.messenger.transceiver(self.options.defaultIframeId, "orientationChange");
    });
    self.disconnectOwnEventsOnWindowUnload();

    if (ori.device) {
        ori.device.bindResize(
            function() {
                var w,
                    iframeId,
                    $parent,
                    $iframesScrollbar = $(self.options.iframeParentContainer);
                $iframesScrollbar.each(function(index, item) {
                        iframeId = "#" + $(item).find("iframe").attr("id");
                        $parent = $(iframeId).parent(self.options.iframeParentContainer);
                        w = $parent.width();
                        if (!w) {
                            w = "100%";
                        }
                        $(iframeId).css({ width: w });
                        if (ori.virtualScrollBar && typeof ori.virtualScrollBar.updateEshopHorizontalScrollBar === "function") {
                            ori.virtualScrollBar.updateEshopHorizontalScrollBar();
                        }
                    }
                );
            });
    }

})(jQuery);
