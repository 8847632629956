/*
--------------------------------------------------------------------------------
Login popup
--------------------------------------------------------------------------------

<![CDATA[

*/

(function ($) {
    "use strict";

    if (typeof ori !== "object") return;

    var self,
        LOGIN_URL = "login-url",
        POPUP_LOGIN = ".popup-login",
        DATA_LOGIN_URL = "data-" + LOGIN_URL,
        POPUP_LOGIN_LINK = ".js-popup-login-link",
        POPUP_LOGIN_WINDOW = "popup-login-window",
        POPUP_LOGIN_WINDOW_ID = "#" + POPUP_LOGIN_WINDOW,
        POPUP_LOGIN_WINDOW_IFRAME = "popup-login-window-iframe",
        POPUP_FORGOTTEN_PASS = "popup-forgotten-pass",
        POPUP_FORGOTTEN_PASS_ID = "#" + POPUP_FORGOTTEN_PASS,
        FORGOTTEN_PASS_URL = "/system/UI/LoginPopup/ForgottenPassword",
        FORGOTTEN_IFRAME_SELECTOR = "initForgotPasswordIfrm",
        FORGOTTEN_PASSWORD_LINK = ".forgot-password-link",
        USER_INPUT = "input.js-user-input",
        KENDO_WINDOW_PADDING_H = 70,
        KENDO_WINDOW_PADDING_V = 60,
        IFRAME_RESIZED = "iframeResized.popup",
        LOGIN_POPUP_FORM = ".js-login-popup-form",
        LOGIN_TAB_FORM = ".js-login-tab-form",
        isNewLayout = false,
        SIZE_2_INDEX = 1;

    ori.popup.addModule("popupLogin", {

        // common used selectors
        sel: {
            ssoLoginSelector: ".js-sso-login",
            ssoLogin: "#ssoLogin",
            newLoginSelector: ".js-new-popup-login",
            newLogin: "#newLogin",
            smallSpinner: "k-loading",
            socialLogin: ".social-login .secondary",
            loginForm: ".w-login",
            loginFormSubmitButton: ".submit-button",
            loginFormUserName: ".w-control.login input",
            loginFormPassword: ".w-control.password input"
        },

        newLoginUrl: "",
        ssoLoginUrl: "",
        ssoAdLoginUrl: "",
        changePasswordPopupUrl: "",

        selLoginPopup: "",

        currentBp: 0,

        /**
        * Initialization of default lightbox options.
        */
        init: function (options) {
            self.setOptions(options);
            isNewLayout = self.options.isNewLoginLayout;

            var $popupLogin = $(POPUP_LOGIN);
            if ($popupLogin.length && $popupLogin.data(LOGIN_URL) != "") {
                $popupLogin
                    .attr("href", $popupLogin.data(LOGIN_URL))
                    .removeAttr(DATA_LOGIN_URL);
            }

            initPopupLoginWindow();
            self.bindSsoLoginEvents();

            self.newLoginUrl = options.url.newLoginUrl;
            self.ssoLoginUrl = options.url.ssoLoginUrl;
            self.ssoAdLoginUrl = options.url.ssoAdLoginUrl;
            self.changePasswordPopupUrl = options.url.changePasswordPopupUrl;
            self.selLoginPopup = options.isNextShoppingEnabled ? self.sel.ssoLogin : self.sel.newLogin;
            self.currentBp = ori.device.activeBreakpoint.index;

            // open login dialogue by url parameter
            if (self.parameterValue("open-login")) {
                //open the login popup
                (isNewLayout && !options.isNextShoppingEnabled) ? ori.login.openNewLogin(self.newLoginUrl) : createPopupLoginWindow();
            } else if (options.isNextShoppingEnabled === true) {
                var isBp5 = ori.device.isFifthBreakpoint;
                if (options.open === true && isBp5) {
                    self.openSsoLogin();
                } else if (options.openChangePwd === true && isBp5) {
                    self.openChangePassword();
                }
            } else if (isNewLayout) {
                showNewPopupLoginWindow(options);
            }

            $(self.sel.ssoLoginSelector).on(self.eventString("click"), function (e) {
                if (e.ctrlKey || e.metaKey) {
                    window.location.href = self.ssoAdLoginUrl;
                } else {
                    self.openSsoLogin();
                }
            });
        },

        bindSsoLoginEvents: function () {
            var loginDialog = $(self.sel.ssoLogin).data("kendoWindow");

            if (!loginDialog) return;

            loginDialog
                .bind("close", self.onLoginDialogClosed)
                .bind("activate", function () {
                    var $input = $(self.selLoginPopup).find(USER_INPUT);
                    $input.length && $input.focus();
                });
        },

        onPopupScreenResize: function () {
            var bp12 = ori.device && ori.device.activeBreakpoint.index <= SIZE_2_INDEX,
                loginPopup = $(self.selLoginPopup).data("kendoWindow");
            var newBp = ori.device.activeBreakpoint.index;

            // close Login popup
            if (bp12 && loginPopup && self.currentBp !== newBp) {
                loginPopup.close();
            }
            self.currentBp = newBp;
        },

        openSsoLogin: function (username) {
            var self = this;
            var loginDialog = $(self.sel.ssoLogin).data("kendoWindow");
            var newUrl = self.ssoLoginUrl;
            var urlSeparator = self.ssoLoginUrl.indexOf("?") > -1 ? "&" : "?";
            // copy returnUrl from main link (not 100% sure if this is needed at all)
            if (window.location.href.indexOf("returnUrl") > -1) {
                var returnUrl = self.getParameterByName("returnUrl", window.location.href);
                newUrl += urlSeparator + "returnUrl=" + returnUrl;
                urlSeparator = "&";
            }

            if (username) {
                newUrl += urlSeparator + "userName=" + username;
            }

            loginDialog.open();
            loginDialog.setOptions({ autoFocus: false });
            
            ori.spinner.applyOverlayTo($(self.sel.ssoLogin));

            $.ajax({
                url: newUrl,
                xhrFields: { withCredentials: true }
            }).done(function (result) {
                ori.device.unbindMedia(self.ns, self.onPopupScreenResize);
                ori.device.unbindResize(self.ns, self.onPopupScreenResize);

                loginDialog.content(result).center();
                ori.spinner.removeOverlayFrom($(self.sel.ssoLogin));

                ori.device.bindMedia(self.onPopupScreenResize, self.ns);
                ori.device.bindResize(self.onPopupScreenResize, self.ns);
            });
        },

        openNewLogin: function() {
            ori.device.unbindMedia(self.ns, self.onPopupScreenResize);
            ori.device.unbindResize(self.ns, self.onPopupScreenResize);

            ori.login.openNewLogin(self.newLoginUrl);

            ori.device.bindMedia(self.onPopupScreenResize, self.ns);
            ori.device.bindResize(self.onPopupScreenResize, self.ns);
        },

        openChangePassword: function () {
            var self = this;
            var loginDialog = $(self.sel.ssoLogin).data("kendoWindow");

            $.ajax({
                url: self.changePasswordPopupUrl,
                xhrFields: { withCredentials: true }
            }).done(function (result) {
                loginDialog
                .bind("close", self.onLoginDialogClosed)
                .content(result)
                .open();
            });
        },

        getParameterByName: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },

        onLoginDialogRefreshed: function (dialog, message) {
            dialog.element.find(".js-login-message").text(message);
        },

        onLoginDialogClosed: function () {
            ori.authorization.removeLoggedUserCookie();
            // $.trigger() does not work for some reason, so we have to resort to these ancient spells
            var event = document.createEvent("Event");
            event.initEvent("loginPopupClosed", true, true);
            document.documentElement.dispatchEvent(event);
        },

        initPopupLoginForm: function () {
            var self = ori.popup.popupLogin;

            $(FORGOTTEN_PASSWORD_LINK).on(self.eventString("click"), self.forgotPasswordClickHandler);

            $(LOGIN_POPUP_FORM).on(self.eventString("submit"), function() {
                disableSubmitButton($(this));
            });
        },

        initLoginForm: function () {
            var self = ori.popup.popupLogin;

            $(LOGIN_TAB_FORM).on(self.eventString("submit"), function() {
                disableSubmitButton($(this));
            });
        },

        forgotPasswordClickHandler: function () {
            var self = ori.popup.popupLogin,
                crossDomain = false;
            try {
                // we try to access parent frame; if this is without error, we are probably
                // on the same domain, otherwise crossdomain communication is needed
                var x = parent.location.href;
            } catch (error) {
                crossDomain = true;
            }
            self.info(
                "Calling forgot password window from page %s, cross-domain : %s ...",
                location.href, crossDomain
            );
            if (crossDomain) {
                postMessage("?action=forgotPassword", "*");
            } else {
                var loginModule = parent.ori.popup.popupLogin || parent.ori.burgerMenu;
                if (!loginModule) {
                    self.warning(
                        "Login module or burger menu not found in parent page, " +
                        "can't call forgot password functionality."
                    );
                    return;
                }
                loginModule.openForgotPasswordDialogue();
            }
        },

        onForgottenPassScreenResize: function () {
            var kendoForgotenPopup = $(POPUP_FORGOTTEN_PASS_ID).data("kendoWindow");
            // close Login popup
            if (ori.device && !ori.device.isFifthBreakpoint && kendoForgotenPopup) {
                 kendoForgotenPopup.destroy();
            }
        },

        openForgotPasswordDialogue: function () {
            var messenger = ori.messenger,
                $forgottenPassWindow = ori.popup.preparePopupWindow(POPUP_FORGOTTEN_PASS),
                kendoLoginPopup = $(POPUP_LOGIN_WINDOW_ID).data("kendoWindow");

            if (typeof messenger !== "object") return;
            $forgottenPassWindow.addClass("k-window-no-overflow");

            kendoLoginPopup && kendoLoginPopup.close();
            $forgottenPassWindow.kendoWindow({
                content: FORGOTTEN_PASS_URL,
                visible: false,
                showOverlay: true,
                width: 400 + KENDO_WINDOW_PADDING_H,
                title: false,
                resizable: false,
                draggable: false,
                open: function () {
                    if (isNewLayout) {
                        var kendoNewLoginPopup = $(self.sel.newLogin).data("kendoWindow");
                        kendoNewLoginPopup && kendoNewLoginPopup.close();
                    }
                    ori.spinner && ori.spinner.applyOverlayTo(POPUP_FORGOTTEN_PASS_ID);
                },
                deactivate: function () {
                    messenger.off(self.eventString(IFRAME_RESIZED));
                    this.destroy();
                },
                refresh: function () {
                    var thisKendoWindow = this;
                    messenger.on(self.eventString(IFRAME_RESIZED), function (event) {
                        if (!event.success) return;

                        var iframeProps = {},
                            width = event.width === "fixed" ? "auto" : parseInt(event.width) + KENDO_WINDOW_PADDING_H,
                            height = event.height === "fixed" ? "auto" : parseInt(event.height) + KENDO_WINDOW_PADDING_V;
                        thisKendoWindow.setOptions({
                            width: width,
                            height: height
                        });
                        // Remove width / height auto because the iframe doesn't like it
                        if (width !== "auto")
                            iframeProps.width = width;
                        if (height !== "auto")
                            iframeProps.height = height;
                        thisKendoWindow.wrapper.find("#" + FORGOTTEN_IFRAME_SELECTOR).attr(iframeProps);
                        ori.spinner && ori.spinner.removeOverlayFrom(POPUP_FORGOTTEN_PASS_ID);
                    });
                }
            });
            $forgottenPassWindow.data("kendoWindow").open();

            ori.device.unbindMedia(self.ns, self.onForgottenPassScreenResize);
            ori.device.unbindResize(self.ns, self.onForgottenPassScreenResize);
            ori.device.bindMedia(self.onForgottenPassScreenResize, self.ns);
            ori.device.bindResize(self.onForgottenPassScreenResize, self.ns);
        },

        openForgotPasswordDialogueBurgerMenu: function () {
            $(FORGOTTEN_PASSWORD_LINK).click(function () {
                var iframeAction = "forgotPasswordToggle",
                    toggle = "forgot";
                parent.postMessage("?action=" + iframeAction + "&toggle=" + toggle, "*");
            });
        }
    });

    function disableSubmitButton($form) {
        var self = ori.popup.popupLogin;

        var validator = $form.kendoValidator().data("kendoValidator");
        if (validator.validate()) {
            var $button = $(self.sel.loginFormSubmitButton, $form);

            $button.toggleClass(self.sel.smallSpinner + " right", true);
            $button.prop('disabled', true);
        };
    }

    function initPopupLoginWindow() {
        var $loginLink = $(POPUP_LOGIN_LINK);

        if (!$loginLink.length || typeof ori.popup !== "object") return;

        var $loginPopupWindow = ori.popup.preparePopupWindow(POPUP_LOGIN_WINDOW).addClass("k-window-no-overflow"),
            kendoLoginPopupWindow,
            iframeContentLoaded = false;

        // Opens the login popup, in case of returnUrl = true for example; comes from StickyBar.cshtml
        self.options.open && ori.device.isFifthBreakpoint && createPopupLoginWindow();

        $loginLink.on(self.eventString("click"), createPopupLoginWindow);

        function createPopupLoginWindow() {
            if (kendoLoginPopupWindow) {
                kendoLoginPopupWindow.open();
            } else {
                $loginPopupWindow.kendoWindow({
                    iframe: true,
                    content: $loginLink.attr("href"),
                    visible: false,
                    modal: true,
                    width: (isNewLayout ? 400 : (self.options.isNextShoppingEnabled ? 516 : 648)) + KENDO_WINDOW_PADDING_H,
                    title: false,
                    resizable: false,
                    draggable: false,
                    open: function () {
                        ori.spinner && ori.spinner.applyOverlayTo(POPUP_LOGIN_WINDOW_ID);
                    },
                    activate: function () {
                        if (iframeContentLoaded) {
                            ori.spinner && ori.spinner.removeOverlayFrom(POPUP_LOGIN_WINDOW_ID);
                        }
                    },
                    refresh: function () {
                        ori.spinner && ori.spinner.removeOverlayFrom(POPUP_LOGIN_WINDOW_ID);
                        iframeContentLoaded = true;
                    }
                });
                kendoLoginPopupWindow = $loginPopupWindow.data("kendoWindow");
                var $iframe = kendoLoginPopupWindow.wrapper.find("iframe");
                $iframe.attr({
                    scrolling: "no",
                    id: POPUP_LOGIN_WINDOW_IFRAME
                });
                kendoLoginPopupWindow.open();
            }
        }
    }

    function showNewPopupLoginWindow(options) {
        $(self.sel.newLoginSelector).on(self.eventString("click"), function (event) {
            ori.login.openNewLogin(self.newLoginUrl);
        });

        // open popup
        if (options.open === true && ori.device.isFifthBreakpoint) {
            self.openNewLogin();
        }
    }

    self = ori.popup.popupLogin;
})(jQuery);

/* ]]> */